export default {
  baseStyle: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: "16px",
    textTransform: "uppercase",
    borderRadius: "999px",
    borderWidth: "1px",
    minW: "150px",
    minWidth: "150px",
    py: 6,
  },
  variants: {
    primary: {
      color: "textLight",
      bgColor: "primary",
      borderColor: "primary",
      _hover: {
        _disabled: {
          bgColor: "primary",
          borderColor: "primary",
        },
      },
    },
    accent1: {
      color: "textDark",
      bgColor: "accent1",
      borderColor: "accent1",
    },
    accent2: {
      color: "textLight",
      bgColor: "accent2",
      borderColor: "accent2",
    },
    destructive: {
      color: "textLight",
      bgColor: "error",
      borderColor: "error",
      _hover: {
        _disabled: {
          bgColor: "error",
          borderColor: "error",
        },
      },
    },
    cancel: {
      color: "textDark",
      bgColor: "background",
      borderColor: "grey",
    },
    link: {
      color: "primary",
      bgColor: "transparent",
      textDecoration: "underline",
      textTransform: "none",
      border: "none",
      letterSpacing: 0,
      minW: "unset",
      minWidth: "unset",
      py: 0,
    },
  },
  sizes: {},
  defaultProps: {
    variant: "primary",
  },
}
