export const Tooltip = {
  baseStyle: {
    color: "textDark",
    bgColor: "accent1",
    px: 4,
    py: 2,
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
