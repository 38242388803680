const accountNavigationStyle = {
  fontWeight: 500,
  px: 4,
  py: 1,
  borderRadius: "999px",
  cursor: "pointer",
}

export default {
  baseStyle: {
    color: "inherit",
    fontFamily: "Quicksand, sans-serif",
    fontSize: "16px",
    lineHeight: 1,
  },
  variants: {
    semibold: {
      fontWeight: 500,
    },
    bold: {
      fontWeight: 600,
    },
    announcement: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.2,
      textAlign: "center",
    },
    servicePrice: {
      fontSize: "32px",
      letterSpacing: 0.5,
      lineHeight: 1,
    },
    serviceCompareAtPrice: {
      fontSize: "32px",
      letterSpacing: 0.5,
      lineHeight: 1,
      textDecoration: "line-through",
      color: "textMuted",
    },
    servicePriceSuffix: {
      fontSize: "14px",
      lineHeight: 1,
    },
    ribbon: {
      fontSize: "13px",
      lineHeight: 1.1,
      color: "textLight",
    },
    accountNavigation: {
      ...accountNavigationStyle,
    },
    accountNavigationSelected: {
      ...accountNavigationStyle,
      backgroundColor: "accent1",
    },
  },
  sizes: {
    xl: {
      fontSize: "20px",
      lineHeight: "36px",
    },
    lg: {
      fontSize: "18px",
      lineHeight: "32px",
    },
    md: {
      fontSize: "16px",
      lineHeight: "28px",
    },
    sm: {
      fontSize: "14px",
      lineHeight: "25.2px",
    },
    xs: {
      fontSize: "11px",
      lineHeight: "17.6px",
    },
  },
  defaultProps: {
    size: "md",
  },
}
