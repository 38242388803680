import React, { useEffect, useMemo, useState } from "react"

type ActiveDrawer = "menu" | null

type ContextProps = {
  activeDrawer: ActiveDrawer
  setActiveDrawer: React.Dispatch<React.SetStateAction<ActiveDrawer>>
  pageAtTop: boolean
}

export const AppContext = React.createContext<ContextProps | null>(null)

export const AppProvider: React.FC<any> = ({ children }) => {
  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(null)
  const [pageAtTop, setPageAtTop] = useState(true)

  useEffect(() => {
    const handleScroll = () =>
      setPageAtTop(typeof window !== "undefined" ? window.scrollY === 0 : true)
    handleScroll()
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true })
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const contextValue = useMemo<ContextProps>(
    () => ({
      activeDrawer,
      setActiveDrawer,
      pageAtTop,
    }),
    [activeDrawer, setActiveDrawer, pageAtTop]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps =>
  ({ ...React.useContext(AppContext) }) as ContextProps
