import * as React from "react"
import { useState } from "react"
import { Box, Container, Flex } from "@chakra-ui/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { SanitySiteSettings } from "../../graphql/settings/siteSettingsFragment"
import CustomImage from "../common/CustomImage"
import MenuIcon from "./MenuIcon"
import { SanityHeader } from "../../graphql/global/headerFragment"
import { SanityHeaderItem } from "../../graphql/objects/headerItemFragment"
import { useAppContext } from "../../providers/app"
import HeaderItem from "./HeaderItem"
import Announcement from "./Announcement"
import CustomLink from "../common/CustomLink"
import config from "../../../config"
import { VscAccount } from "react-icons/vsc"
import Icon from "../common/Icon"

type Props = {
  location: Location
}

type Query = {
  site: SanitySiteSettings
  header: SanityHeader
}

const Header: React.FC<Props> = ({ location }) => {
  const { pageAtTop } = useAppContext()
  const { site, header } = useStaticQuery<Query>(graphql`
    query {
      site: sanitySiteSettings {
        ...SITE_SETTINGS_FRAGMENT
      }
      header: sanityHeader {
        ...HEADER_FRAGMENT
      }
    }
  `)

  const [dropdownIndex, setDropdownIndex] = useState<number | null>(null)

  return (
    <Box color="textLight" bgColor="primary" pos="fixed" top={0} left={0} right={0} zIndex={10}>
      {header?.announcements && header?.announcements?.length > 0 ? (
        <Announcement lines={header?.announcements} />
      ) : null}
      <Container
        maxW="container.desktop"
        py={{ base: 3, md: pageAtTop ? 6 : 3 }}
        transition="0.2s padding-top, 0.2s padding-bottom"
      >
        <Box h="40px" display={{ md: "none" }} pos="relative">
          <MenuIcon />
          <Box
            w="100px"
            pos="absolute"
            h="100%"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
          >
            <CustomImage
              as={Link}
              to="/"
              image={site?.logo}
              h="100%"
              srcSetWidth={300}
              objectFit="contain"
              display="block"
            />
          </Box>
          <CustomLink
            layout="custom"
            url={config.routes.ACCOUNT}
            pos="absolute"
            right={0}
            visibility="hidden"
          >
            <Icon icon={<VscAccount />} p={2} w="40px" h="40px" />
          </CustomLink>
        </Box>
        <Flex
          textAlign="center"
          justifyContent="space-between"
          alignItems="center"
          h={{ base: "60px", md: pageAtTop ? "60px" : "40px" }}
          display={{ base: "none", md: "flex" }}
          transition="0.2s height"
        >
          <CustomImage
            as={Link}
            to="/"
            image={site?.logo}
            w="131px"
            h="100%"
            srcSetWidth={300}
            objectFit="contain"
            mr="20px"
          />
          <Flex flex={1} justifyContent="center" alignItems="center" flexWrap="wrap">
            {header?.headerItems?.map((item: SanityHeaderItem, index: number) => (
              <HeaderItem
                key={item._key}
                item={item}
                dropdownVisible={index === dropdownIndex}
                location={location}
                onDropdownTrigger={() => setDropdownIndex(index)}
                onDropdownDismiss={() => index === dropdownIndex && setDropdownIndex(null)}
              />
            ))}
          </Flex>
          <CustomLink layout="custom" url={config.routes.ACCOUNT} ml="20px" visibility="hidden">
            <Icon icon={<VscAccount />} p={2} w="40px" h="40px" />
          </CustomLink>
        </Flex>
      </Container>
    </Box>
  )
}

export default React.memo(Header)
