import React, { useEffect, useMemo, useState } from "react"
import { Firestore, getFirestore } from "firebase/firestore"
import { Auth, getAuth } from "firebase/auth"
import { initializeApp } from "firebase/app"
import config from "../../config"
import { AuthUser, UserRole } from "../types/firebaseTypes"

type AuthState = "authed" | "determining" | "unauthed"

type ContextProps = {
  firestore: Firestore
  auth: Auth
  authState: AuthState
  user: AuthUser | null
}

export const FirebaseContext = React.createContext<ContextProps | null>(null)

export const FirebaseProvider: React.FC<any> = ({ children }) => {
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [user, setUser] = useState<AuthUser | null>(null)

  const app = useMemo(() => {
    return initializeApp(config.firebase.appConfig)
  }, [])

  const firestore = useMemo(() => getFirestore(app), [app])

  const auth = useMemo(() => getAuth(app), [app])

  const authState: AuthState = useMemo(() => {
    if (user) {
      return "authed"
    } else if (isUserLoading) {
      return "determining"
    } else {
      return "unauthed"
    }
  }, [isUserLoading, user])

  useEffect(() => {
    const auth = getAuth(app)
    auth.onAuthStateChanged(user => {
      setIsUserLoading(true)
      setUser(null)

      if (user) {
        user
          .getIdTokenResult(true)
          .then(result => {
            setIsUserLoading(false)

            const role = (result?.claims?.role || "") as string
            if (["owner", "admin", "pawrent"].includes(role)) {
              setUser({
                ...user,
                role: role as UserRole,
                token: result.token,
              })
            }
          })
          .catch(() => {
            setUser(null)
          })
      } else {
        setIsUserLoading(false)
      }
    })
  }, [app])

  const contextValue = useMemo<ContextProps>(
    () => ({
      firestore,
      auth,
      authState,
      user,
    }),
    [firestore, auth, authState, user]
  )

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>
}

export const useFirebaseContext = (): ContextProps =>
  ({ ...React.useContext(FirebaseContext) }) as ContextProps
