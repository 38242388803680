export default {
  primary: "#376E94",
  primaryMuted: "#9DBDD6",
  accent1: "#f3e7d1",
  accent2: "#d26a50",
  ribbon: "#e67575",
  background: "#fff",
  greyLight: "#fafafa",
  grey: "#e0e0e0",
  textDark: "#000",
  textMuted: "#888",
  textLight: "#fff",
  error: "#d54a26",
  malePanel: "#cbdeef",
  maleSymbol: "#2986cc",
  femalePanel: "#f1dce6",
  femaleSymbol: "#c90076",
}
