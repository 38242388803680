import * as React from "react"
import { ReactNode, useMemo } from "react"
import { Box, BoxProps, Flex, Text } from "@chakra-ui/react"
import { PortableText } from "@portabletext/react"
import { SanityRichText } from "../../graphql/objects/richTextFragment"
import CustomLink from "./CustomLink"
import { SanityLink } from "../../graphql/objects/linkFragment"
import ResponsiveHeading from "./ResponsiveHeading"
import { FaPaw } from "react-icons/fa"
import Icon from "./Icon"
import { useHorizontalFlexAlign } from "../../hooks/useHorizontalFlexAlign"

type SanityRichTextLink = SanityLink & {
  style?: "inline" | "button"
}

type Props = {
  richText?: SanityRichText
} & BoxProps

const RichText: React.FC<Props> = ({ richText, ...props }) => {
  const justifyContent = useHorizontalFlexAlign(richText?.textAlignment)

  const components = useMemo(
    () => ({
      block: {
        h1: ({ children }: { children: ReactNode }) => (
          <ResponsiveHeading level={1} mb={12}>
            {children}
          </ResponsiveHeading>
        ),
        h2: ({ children }: { children: ReactNode }) => (
          <ResponsiveHeading level={2} mt={12}>
            {children}
          </ResponsiveHeading>
        ),
        h3: ({ children }: { children: ReactNode }) => (
          <ResponsiveHeading level={3} mt={12}>
            {children}
          </ResponsiveHeading>
        ),
        bodylg: ({ children }: { children: ReactNode }) => (
          <Text size="lg" my={4}>
            {children}
          </Text>
        ),
        bodymd: ({ children }: { children: ReactNode }) => (
          <Text size="md" my={4}>
            {children}
          </Text>
        ),
        bodysm: ({ children }: { children: ReactNode }) => (
          <Text size="sm" my={4}>
            {children}
          </Text>
        ),
        bodyxs: ({ children }: { children: ReactNode }) => (
          <Text size="xs" my={4}>
            {children}
          </Text>
        ),
        blockquote: ({ children }: { children: ReactNode }) => (
          <Text color="textMuted" borderLeft="1px solid" borderColor="textMuted" my={4} pl={4}>
            {children}
          </Text>
        ),
        spacer: ({ children }: { children: ReactNode }) => (
          <Box className="spacer" h="10px">
            {children}
          </Box>
        ),
      },
      listItem: {
        bullet: ({ children }: { children: ReactNode }) => (
          <Flex
            justifyContent={justifyContent}
            my={2}
            sx={{
              ":first-child": { mt: 0 },
              ":last-child": { mb: 0 },
            }}
          >
            <Icon icon={<FaPaw />} w="24px" h="24px" mt="3px" />
            <Text flex={1} ml={4}>
              {children}
            </Text>
          </Flex>
        ),
        number: ({ children }: { children: ReactNode }) => (
          <Flex
            justifyContent={justifyContent}
            alignItems="center"
            sx={{
              counterIncrement: "counter",
              ":first-child": { mt: 0 },
              ":last-child": { mb: 0 },
            }}
          >
            <Box pos="relative" w="24px" h="24px" mt="3px">
              <Icon icon={<FaPaw />} w="100%" h="100%" pos="absolute" />
              <Text
                size="xs"
                color="textLight"
                textAlign="center"
                pos="absolute"
                left={0}
                right={0}
                bottom="-4%"
                _before={{ content: "counter(counter)" }}
              />
            </Box>
            <Text flex={1} ml={4}>
              {children}
            </Text>
          </Flex>
        ),
      },
      marks: {
        link: ({ value, children }: { value: SanityRichTextLink; children: ReactNode }) => {
          const style = value?.style || "inline"
          return (
            <CustomLink layout={style} variant="accent1" link={value} textDecoration={style === "inline" ? "underline" : "none"}>
              {children}
            </CustomLink>
          )
        },
      },
    }),
    []
  )

  const maxW = useMemo(() => {
    switch (richText?.maxWidth) {
      case "small":
        return "400px"
      case "medium":
        return "550px"
      case "large":
        return "700px"
      default:
      case "full":
        return "100%"
    }
  }, [richText?.maxWidth])

  return (
    <Box {...props}>
      <Box
        maxW={maxW}
        mx="auto"
        textAlign={richText?.textAlignment || "left"}
        sx={{
          ol: {
            counterReset: "counter",
          },
          "ol, ul": {
            my: 4,
          },
          "> *": {
            ":first-child": { mt: 0 },
            ":last-child": { mb: 0 },
          },
          ".spacer + *": {
            mt: 0,
          },
        }}
      >
        {/* @ts-ignore */}
        <PortableText value={richText?.primitiveRichText} components={components} />
      </Box>
    </Box>
  )
}

export default React.memo(RichText)
