import * as React from "react"
import { Box, ChakraProvider } from "@chakra-ui/react"
import Header from "../header/Header"
import Footer from "./Footer"
import theme from "../../theme/theme"
import { AppProvider } from "../../providers/app"
import MenuDrawer from "../header/MenuDrawer"
import Global from "./Global"
import { useMaintenance } from "../../hooks/useMaintenance"
import { FirebaseProvider } from "../../providers/firebase"
import { AnyMap } from "../../types/types"

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    grecaptcha: any
    // eslint-disable-next-line no-unused-vars
    gtag: (command: "event", commandType: string, parameters: AnyMap) => void
  }
}

type RootProps = {
  element: any
  props: any
}

const Root: React.FC<RootProps> = ({ element, props }) => {
  const { isMaintenanceEnabled } = useMaintenance()

  return (
    <AppProvider>
      <FirebaseProvider>
        <ChakraProvider theme={theme} {...props}>
          <Global />
          {!isMaintenanceEnabled && <Header location={props?.location} />}
          <Box minH="100vh" pos="relative">
            {element}
          </Box>
          {!isMaintenanceEnabled && <Footer />}
          <MenuDrawer />
        </ChakraProvider>
      </FirebaseProvider>
    </AppProvider>
  )
}

export default React.memo(Root)
