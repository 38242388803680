import * as React from "react"
import { Box, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import { SanityHeader } from "../../graphql/global/headerFragment"
import { useAppContext } from "../../providers/app"
import CustomLink from "../common/CustomLink"
import { SanityHeaderItem } from "../../graphql/objects/headerItemFragment"
import Icon from "../common/Icon"
import { GrClose } from "react-icons/gr"

type Query = {
  header: SanityHeader
}

const MenuDrawer: React.FC = () => {
  const { activeDrawer, setActiveDrawer } = useAppContext()
  const { header } = useStaticQuery<Query>(graphql`
    query {
      site: sanitySiteSettings {
        ...SITE_SETTINGS_FRAGMENT
      }
      header: sanityHeader {
        ...HEADER_FRAGMENT
      }
    }
  `)

  return (
    <Drawer isOpen={activeDrawer === "menu"} placement="left" onClose={() => setActiveDrawer(null)}>
      <DrawerOverlay />
      <DrawerContent bgColor="accent1">
        <DrawerBody p={0}>
          <Flex flexDir="column" h="100%">
            <Box p={4}>
              <Icon
                icon={<GrClose />}
                p={2}
                w="40px"
                h="40px"
                display="inline-block"
                cursor="pointer"
                onClick={() => setActiveDrawer(null)}
              />
            </Box>
            <Box flex={1} overflowY="scroll">
              {header?.headerItems?.map((item: SanityHeaderItem) => (
                <CustomLink
                  key={item._key}
                  layout="inline"
                  link={item?.link}
                  size="md"
                  px={6}
                  py={4}
                  display="block"
                  onClick={() => setActiveDrawer(null)}
                />
              ))}
              <Divider my={2} />
              {header?.mobileHeaderFooterItems?.map((item: SanityHeaderItem) => (
                <CustomLink
                  key={item._key}
                  layout="inline"
                  link={item?.link}
                  size="md"
                  px={6}
                  py={4}
                  display="block"
                  onClick={() => setActiveDrawer(null)}
                />
              ))}
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default React.memo(MenuDrawer)
