module.exports = {
  sanity: {
    projectId: "x0ekj4yn",
    dataset: "production",
  },
  firebase: {
    appConfig: {
      apiKey: "AIzaSyA_xbrYrwYLsGlaRQMZ4Zjv9KXmWAyDHzo",
      authDomain: "tiny-tails-retreat-website.firebaseapp.com",
      projectId: "tiny-tails-retreat-website",
      storageBucket: "tiny-tails-retreat-website.appspot.com",
      messagingSenderId: "652603449181",
      appId: "1:652603449181:web:8ebb86d9da7bf58a9512b4",
    },
  },
  routes: {
    ACCOUNT: "/account",
    USERS: "/account/users",
    PAWRENTS: "/account/pawrents",
    PAWRENT: "/account/pawrent",
    LOGIN: "/account/login",
    FORGOT_PASSWORD: "/account/forgot-password",
  },
  firestore: {
    collections: {
      USERS: "users",
      PAWRENTS: "pawrents",
      INTERNAL: "internal",
      DOGS: "dogs",
    },
    documents: {
      FIELDS: "fields",
    },
  },
  square: {
    customFields: {
      EMERGENCY_CONTACT_NAME: "square:f36d7152-6d10-4b2f-a98e-67f73e69fd8a",
      EMERGENCY_CONTACT_PHONE: "square:998760aa-2296-4b65-9622-deaf31f1423b",

      DOG_NAME_1: "square:2315cd61-6892-41bb-9d33-e44ae077d3e3",
      DOG_BREED_1: "square:0e118eb1-bf64-45b4-8498-582dc6442427",
      DOG_COLOR_1: "square:bdbbbb09-bbdc-4804-affc-c76a8d2aff0f",
      DOG_GENDER_1: "square:c796b2a0-83bc-46cc-8f4a-9ee6ebc2e0db",
      DOG_DESEXED_1: "square:ff9358b7-11f3-4af2-972c-5def154a452b",
      DOG_TREATS_1: "square:624a6a3c-8637-4007-af40-43ee44861cf8",
      DOG_MEDICAL_NOTES_1: "square:6e5b7187-0dd1-4d90-b594-55836ee923b5",
      DOG_BEHAVIORAL_NOTES_1: "square:d189d020-2f29-4ac7-86dc-1fb19f02ce2d",

      DOG_NAME_2: "square:fa07ff39-9227-4f4d-b7a9-51741118e773",
      DOG_BREED_2: "square:69f00a47-9319-4bd4-9021-f791ff269cc5",
      DOG_COLOR_2: "square:0c8db95e-e658-40b6-9047-f79d34709ba0",
      DOG_GENDER_2: "square:edb47675-c53f-4107-ba52-97da55683dde",
      DOG_DESEXED_2: "square:0dbee511-8a6b-4073-842f-e57dee7590f5",
      DOG_TREATS_2: "square:a3af322e-a063-4222-a293-9bbcf44f5758",
      DOG_MEDICAL_NOTES_2: "square:55c6eeb8-955d-48e4-99e4-65bfc3cd5f27",
      DOG_BEHAVIORAL_NOTES_2: "square:0a1201b3-073d-4206-b811-b70fe269d4c0",

      DOG_NAME_3: "square:858d72cc-7632-4976-a6d9-0e34c525b783",
      DOG_BREED_3: "square:6eed803f-94a4-4063-8e6c-7dbe067b3bd3",
      DOG_COLOR_3: "square:139e9956-d4ee-4668-81a8-8f728a86a33b",
      DOG_GENDER_3: "square:dab9a07e-467c-4ad0-b319-331bd82e64c5",
      DOG_DESEXED_3: "square:d92b7df3-3b01-45b0-9ae3-1c58ce5691cf",
      DOG_TREATS_3: "square:d2a4fa1d-e8ec-407c-ac53-88db242280ab",
      DOG_MEDICAL_NOTES_3: "square:e78468a8-9287-47ce-be9a-2d4bb9611ebd",
      DOG_BEHAVIORAL_NOTES_3: "square:e573ebfd-670e-4814-b430-d138368c9fac",
    },
  },
}
