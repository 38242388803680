import * as React from "react"
import { Box, BoxProps, LinkProps } from "@chakra-ui/react"

type Props = {
  icon?: JSX.Element
} & BoxProps & LinkProps

const Icon: React.FC<Props> = ({ icon, ...props }) => {
  return (
    <Box
      sx={{
        svg: {
          w: "100%",
          h: "100%",
        },
      }}
      {...props}
    >
      {icon}
    </Box>
  )
}

export default React.memo(Icon)
